// stylelint-disable declaration-no-important

// Utilities for common `visibility` values

.invisible {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}
