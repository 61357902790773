.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;

  @include active-focus-hover {
    text-decoration: none;
  }

  &.disabled {
    color: $nav-link-color-disabled;
    cursor: default;
  }
}

// Justified

.nav-fill {
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

// Pills

.nav-pills {
  .nav-link {
    @include border-radius($nav-pills-border-radius);
    @include transition-standard(background-color, color, opacity);

    color: $nav-pills-color;
    opacity: $nav-pills-link-opacity;

    @include active-focus-hover {
      background-color: $nav-pills-bg-hover;
    }

    &.active {
      color: $nav-pills-color-active;
      opacity: 1;
    }

    &.disabled {
      background-color: transparent;
      color: $nav-link-color-disabled;
      opacity: 1;
    }

    &:active {
      opacity: 1;
    }
  }

  .show > .nav-link {
    background-color: $nav-pills-bg-hover;
    opacity: 1;
  }
}

// N.B. Tabs are defined in Material design
// (https://material.google.com/components/tabs.html)
// therefore, tabs are styled in /assets/sass/material/_tab.scss

// Tabs (helper classes to work with tabs JavaScript)

.tab-content {
  // Hide to start
  > .tab-pane {
    display: none;
  }

  // Show when activated
  > .active {
    display: block;
  }
}
