.collapse {
  display: none;

  &.show {
    display: block;
  }
}

// stylelint-disable-next-line selector-no-qualifying-type
tbody.collapse.show {
  display: table-row-group;
}

// stylelint-disable-next-line selector-no-qualifying-type
tr.collapse.show {
  display: table-row;
}

.collapsing {
  @include transition-standard(height);

  height: 0;
  overflow: hidden;
  position: relative;
}

.fade {
  @include transition-standard(opacity);

  opacity: 0;

  &.show {
    opacity: 1;
  }
}
