@mixin text-hide($ignore-warning: false) {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  text-shadow: none;

  @if ($ignore-warning != true) {
    @warn 'The `text-hide()` mixin has been deprecated as of v4.1.0. It will be removed entirely in v5.';
  }
}
