// Button (Bootstrap button)
// Based on https://material.google.com/components/buttons.html#buttons-style

// N.B. Material design only specifies specs for default button (.btn) and dense button (.btn-sm)

$btn-bg-active:               rgba(153, 153, 153, 0.4) !default;
$btn-bg-active-inverse:       rgba(204, 204, 204, 0.25) !default;
$btn-bg-disabled:             $black-divider !default;
$btn-bg-disabled-inverse:     $white-divider !default;
$btn-border-radius:           $border-radius !default;
$btn-block-margin-y:          $spacer-xs !default;
$btn-color:                   $black-primary !default;
$btn-color-disabled:          rgba(0, 0, 0, 0.26) !default;
$btn-color-disabled-inverse:  rgba(255, 255, 255, 0.3) !default;
$btn-elevation-shadow:        map-get($elevation-shadows, 2) !default;
$btn-elevation-shadow-active: map-get($elevation-shadows, 8) !default;
$btn-font-weight:             $font-weight-medium !default;
$btn-line-height:             1 !default;
$btn-min-width:               0 !default;
$btn-overlay:                 $black-divider !default;
$btn-overlay-inverse:         $white-divider !default;

$btn-font-size:               0.875rem !default;
$btn-font-size-lg:            0.9375rem !default;
$btn-font-size-sm:            0.8125rem !default;
$btn-height:                  2.25rem !default;
$btn-height-lg:               2.5rem !default;
$btn-height-sm:               2rem !default;
$btn-padding-x:               1rem !default;
$btn-padding-x-lg:            $btn-padding-x !default;
$btn-padding-x-sm:            $btn-padding-x !default;
$btn-padding-y:               (($btn-height - $btn-font-size * $btn-line-height) / 2) !default;
$btn-padding-y-lg:            (($btn-height-lg - $btn-font-size-lg * $btn-line-height) / 2) !default;
$btn-padding-y-sm:            (($btn-height-sm - $btn-font-size-sm * $btn-line-height) / 2) !default;

// Button: floating action button
// Based on https://material.google.com/components/buttons-floating-action-button.html#buttons-floating-action-button-floating-action-button

$btn-float-dropdown-inner-spacer-y: 1rem !default;
$btn-float-elevation-shadow:        map-get($elevation-shadows, 6) !default;
$btn-float-elevation-shadow-active: map-get($elevation-shadows, 12) !default;
$btn-float-size:                    3.5rem !default;
$btn-float-size-sm:                 2.5rem !default;

// Button: toggle buttons (Boostrap button group)
// Based on https://material.io/guidelines/components/buttons.html#buttons-toggle-buttons

$btn-group-bg:             $white-primary !default;
$btn-group-inner-spacer-x: 1px !default;
$btn-group-inner-spacer-y: 1px !default;
$btn-group-toggle-opacity: 0.7 !default;

// Card (Bootstrap card)
// Based on https://material.google.com/components/cards.html#cards-usage

$card-action-inner-spacer-x:  0.5rem !default;
$card-action-inner-spacer-y:  0.25rem !default;
$card-action-padding-x:       0.5rem !default;
$card-action-padding-y:       0.5rem !default;
$card-bg:                     $white !default;
$card-border-color:           $border-color !default;
$card-border-radius:          $border-radius !default;
$card-border-width:           $border-width !default;
$card-columns-count:          2 !default;
$card-columns-count-desktop:  3 !default;
$card-elevation-shadow:       map-get($elevation-shadows, 2) !default;
$card-elevation-shadow-hover: map-get($elevation-shadows, 8) !default;
$card-inner-spacer-x:         1rem !default;
$card-inner-spacer-y:         1rem !default;
$card-margin-x:               $spacer-sm !default;
$card-margin-y:               $spacer-sm !default;
$card-padding-x:              1rem !default;
$card-padding-y:              1rem !default;

// Chip
// Based on https://material.google.com/components/chips.html#chips-specs

$chip-bg:                     $material-color-grey-300 !default;
$chip-bg-active:              $material-color-grey-400 !default;
$chip-color:                  $black-primary !default;
$chip-elevation-shadow-hover: map-get($elevation-shadows, 2) !default;
$chip-font-size:              0.875rem !default;
$chip-font-weight:            $font-weight-regular !default;
$chip-height:                 2rem !default;
$chip-icon-bg:                theme-color(secondary) !default;
$chip-icon-font-size:         1rem !default;
$chip-inner-spacer-x:         0.25rem !default;
$chip-line-height:            1 !default;
$chip-padding-x:              0.75rem !default;

// Data table (Bootstrap table)
// Based on https://material.google.com/components/data-tables.html#data-tables-structure

$table-bg:                    none !default;
$table-bg-active:             rgba(0, 0, 0, 0.07);
$table-bg-active-hover:       rgba(0, 0, 0, 0.07);
$table-bg-accent:             rgba(0, 0, 0, 0.04);
$table-bg-hover:              rgba(0, 0, 0, 0.07);
$table-border-color:          $border-color-solid !default;
$table-border-width:          $border-width !default;
$table-cell-padding-x:        1.75rem !default;
$table-cell-padding-x-alt:    1rem !default;
$table-line-height:           $line-height-base !default;
$table-margin-y:              $spacer !default;
$table-striped-order:         odd !default;

$table-dark-bg:               $dark-theme-4 !default;
$table-dark-bg-accent:        $dark-theme-3 !default;
$table-dark-bg-hover:         $dark-theme-2 !default;
$table-dark-border-color:     $dark-theme-3 !default;
$table-dark-color:            $white-primary !default;

$table-tbody-cell-height:     3rem !default;
$table-tbody-color:           $black-primary !default;
$table-tbody-font-size:       0.8125rem !default;
$table-tbody-font-weight:     $font-weight-regular !default;
$table-tbody-padding-y:       (($table-tbody-cell-height - $table-tbody-font-size * $table-line-height) / 2) !default;

$table-tfoot-cell-height:     2.5rem !default;
$table-tfoot-color:           $black-secondary !default;
$table-tfoot-font-size:       0.75rem !default;
$table-tfoot-font-weight:     $font-weight-regular !default;
$table-tfoot-padding-y:       (($table-tfoot-cell-height - $table-tfoot-font-size * $table-line-height) / 2) !default;

$table-thead-bg:              $table-bg-accent !default;
$table-thead-cell-height:     $table-tfoot-cell-height !default;
$table-thead-color:           $table-tfoot-color !default;
$table-thead-font-size:       $table-tfoot-font-size !default;
$table-thead-font-weight:     $font-weight-medium !default;
$table-thead-padding-y:       (($table-thead-cell-height - $table-thead-font-size * $table-line-height) / 2) !default;

$table-sm-cell-padding-x:     1rem !default;
$table-sm-cell-padding-x-alt: 1rem !default;
$table-sm-tbody-cell-height:  2.25rem !default;
$table-sm-tbody-padding-y:    (($table-sm-tbody-cell-height - $table-tbody-font-size * $table-line-height) / 2) !default;
$table-sm-tfoot-cell-height:  2.0rem !default;
$table-sm-tfoot-padding-y:    (($table-sm-tfoot-cell-height - $table-tfoot-font-size * $table-line-height) / 2) !default;
$table-sm-thead-cell-height:  $table-sm-tfoot-cell-height !default;
$table-sm-thead-padding-y:    (($table-sm-thead-cell-height - $table-thead-font-size * $table-line-height) / 2) !default;

// Dialog (Bootstrap modal)
// Based on https://material.google.com/components/dialogs.html#dialogs-specs

$dialog-backdrop-bg:                  $black-hint !default;
$dialog-border-radius:                $border-radius !default;
$dialog-content-bg:                   $white !default;
$dialog-elevation-shadow:             map-get($elevation-shadows, 24) !default;
$dialog-footer-btn-min-width:         4rem !default;
$dialog-footer-btn-stacked-height:    3rem !default;
$dialog-footer-btn-stacked-padding-x: 1rem !default;
$dialog-footer-btn-stacked-padding-y: (($dialog-footer-btn-stacked-height - $btn-font-size * $btn-line-height) / 2) !default;
$dialog-footer-inner-spacer-x:        0.5rem !default;
$dialog-footer-padding-x:             0.5rem !default;
$dialog-footer-padding-y:             0.5rem !default;
$dialog-margin-x:                     $spacer-lg !default;
$dialog-margin-y:                     $spacer-lg !default;
$dialog-padding-x:                    1.5rem !default;
$dialog-padding-y:                    1.25rem !default;
$dialog-transition-scale:             $transition-scale !default;
$dialog-width:                        35rem !default;
$dialog-width-lg:                     52.5rem !default;
$dialog-width-sm:                     17.5rem !default;

// Expansion panel (Bootstrap list group)
// Based on https://material.google.com/components/expansion-panels.html#expansion-panels-specs

$expansion-panel-bg:                    $white !default;
$expansion-panel-bg-active:             $material-color-grey-200 !default;
$expansion-panel-border-color:          $border-color !default;
$expansion-panel-border-radius:         $border-radius !default;
$expansion-panel-border-width:          $border-width !default;
$expansion-panel-color:                 $black-primary !default;
$expansion-panel-color-disabled:        $black-hint !default;
$expansion-panel-elevation-shadow:      map-get($elevation-shadows, 1) !default;
$expansion-panel-font-size:             0.9375rem !default;
$expansion-panel-footer-btn-min-width:  4rem !default;
$expansion-panel-footer-inner-spacer-x: 0.5rem !default;
$expansion-panel-footer-inner-spacer-y: 0.25rem !default;
$expansion-panel-footer-padding-x:      0.5rem !default;
$expansion-panel-height:                3rem !default;
$expansion-panel-inner-spacer-x:        1rem !default;
$expansion-panel-inner-spacer-y:        1rem !default;
$expansion-panel-line-height:           $line-height-base !default;
$expansion-panel-margin-y:              $spacer !default;
$expansion-panel-padding-x:             1.5rem !default;
$expansion-panel-padding-y:             (($expansion-panel-height - $expansion-panel-font-size * $expansion-panel-line-height) / 2) !default;

// Menu (Bootstrap dropdown)
// Based on https://material.google.com/components/menus.html#menus-specs

$menu-bg:                       $white !default;
$menu-border-radius:            $border-radius !default;
$menu-divider-bg:               $border-color !default;
$menu-font-size:                .8rem !default;
$menu-font-size-cascading:      0.9375rem !default;
$menu-font-weight:              $font-weight-regular !default;
$menu-elevation-shadow:         map-get($elevation-shadows, 8) !default;
$menu-header-color:             $black-primary !default;
$menu-line-height:              1.5rem !default;
$menu-line-height-cascading:    $menu-line-height !default;
$menu-link-bg:                  transparent !default;
$menu-link-bg-hover:            $material-color-grey-100 !default;
$menu-link-color:               $black-primary !default;
$menu-link-color-disabled:      $black-hint !default;
$menu-link-height:              3rem !default;
$menu-link-height-cascading:    2rem !default;
$menu-link-padding-x:           1rem !default;
$menu-link-padding-x-cascading: 1.5rem !default;
$menu-link-padding-y:           (($menu-link-height - $menu-line-height) / 2) !default;
$menu-link-padding-y-cascading: (($menu-link-height-cascading - $menu-line-height-cascading) / 2) !default;
$menu-min-width:                7rem !default;
$menu-min-width-cascading:      20rem !default;
$menu-padding-y:                0.3rem !default;
$menu-padding-y-cascading:      1rem !default;

// Navigation drawer
// Based on https://material.google.com/patterns/navigation-drawer.html#navigation-drawer-specs

$navdrawer-backdrop-bg:             $dialog-backdrop-bg !default;
$navdrawer-border-color:            $border-color !default;
$navdrawer-border-width:            $border-width !default;
$navdrawer-content-bg:              $dialog-content-bg !default;
$navdrawer-divider-bg:              $border-color !default;
$navdrawer-elevation-shadow:        map-get($elevation-shadows, 16) !default;
$navdrawer-gutter-width:            3.5rem !default;
$navdrawer-header-bg:               $material-color-grey-100 !default;
$navdrawer-header-border-color:     $border-color !default;
$navdrawer-header-border-width:     $border-width !default;
$navdrawer-inner-spacer-x:          1rem !default;
$navdrawer-inner-spacer-y:          0.5rem !default;
$navdrawer-nav-icon-color:          $black-secondary !default;
$navdrawer-nav-icon-width:          2rem !default;
$navdrawer-nav-link-bg-hover:       $material-color-grey-300 !default;
$navdrawer-nav-link-color:          $black-primary !default;
$navdrawer-nav-link-color-active:   theme-color(primary) !default;
$navdrawer-nav-link-color-disabled: $black-hint !default;
$navdrawer-nav-link-font-size:      0.875rem !default;
$navdrawer-nav-link-font-weight:    $font-weight-medium !default;
$navdrawer-nav-link-height:         3rem !default;
$navdrawer-subheader-color:         $black-hint !default;
$navdrawer-subheader-font-size:     0.875rem !default;
$navdrawer-subheader-font-weight:   $font-weight-medium !default;
$navdrawer-subheader-height:        3rem !default;
$navdrawer-width:                   17.5rem !default;

// Picker
// Based on https://material.google.com/components/pickers.html#pickers-date-pickers

$picker-border-radius:        $border-radius !default;
$picker-cell-size:            2.5rem !default;
$picker-content-bg:           $dialog-content-bg !default;
$picker-day-bg-selected:      theme-color(primary) !default;
$picker-day-color-disabled:   $black-hint !default;
$picker-day-color-today:      theme-color(primary) !default;
$picker-elevation-shadow:     $dialog-elevation-shadow !default;
$picker-header-bg:            theme-color(primary) !default;
$picker-header-padding-x:     1.5rem !default;
$picker-header-padding-y:     1rem !default;
$picker-holder-bg:            $dialog-backdrop-bg !default;
$picker-inner-spacer-x:       0.5rem !default;
$picker-inner-spacer-y:       0.5rem !default;
$picker-margin-x:             $dialog-margin-x !default;
$picker-margin-y:             $dialog-margin-y !default;
$picker-nav-next-icon:        'keyboard_arrow_right' !default;
$picker-nav-prev-icon:        'keyboard_arrow_left' !default;
$picker-select-bg:            $black-divider !default;
$picker-select-border-radius: $border-radius !default;
$picker-select-opacity:       0.7 !default;
$picker-transition-scale:     $transition-scale !default;
$picker-weekday-color:        $black-hint !default;

// Progress (Bootstrap progress bar)
// Based on https://material.google.com/components/progress-activity.html#progress-activity-types-of-indicators

$progress-bar-bg:                      $material-color-indigo-500 !default;
$progress-bar-border-radius:           0.25rem !default;
$progress-bar-buffer-width:            1.5rem !default;
$progress-bar-height:                  0.25rem !default;
$progress-bg:                          $material-color-indigo-100 !default;

$progress-circular-animation-duration: 5.332s !default;
$progress-circular-bg:                 $material-color-indigo-500 !default;
$progress-circular-height:             2.5rem !default;
$progress-circular-spinner-width:      0.25rem !default;

// Selection control (Bootstrap custom form control)
// Based on https://material.google.com/components/selection-controls.html

$selection-control-checkbox-icon:               'check_box_outline_blank' !default;
$selection-control-checkbox-icon-checked:       'check_box' !default;
$selection-control-checkbox-icon-indeterminate: 'indeterminate_check_box' !default;
$selection-control-color:                       $black-secondary !default;
$selection-control-color-active:                theme-color(secondary) !default;
$selection-control-color-disabled:              rgba(0, 0, 0, 0.26) !default;
$selection-control-indicator-size:              $material-icon-size !default;
$selection-control-radial-opacity:              $black-divider-opacity !default;
$selection-control-radio-icon:                  'radio_button_unchecked' !default;
$selection-control-radio-icon-checked:          'radio_button_checked' !default;
$selection-control-spacer-x:                    ($material-icon-size / 3) !default;
$selection-control-spacer-y:                    ($material-icon-size / 2.5) !default;
$selection-control-thumb-bg:                    $material-color-grey-050 !default;
$selection-control-thumb-bg-disabled:           #bdbdbd !default;
$selection-control-thumb-shadow:                0 1px 5px 0 rgba(0, 0, 0, $black-secondary-opacity) !default;
$selection-control-track-bg:                    $black-hint !default;
$selection-control-track-bg-disabled:           $black-divider !default;
$selection-control-track-height:                1rem !default;
$selection-control-track-opacity:               0.5 !default;
$selection-control-track-width:                 ($selection-control-indicator-size * 2) !default;

// Slider (Bootstrap custom form control)
// Based on https://material.io/guidelines/components/sliders.html#sliders-continuous-slider

$slider-thumb-bg:             theme-color(secondary) !default;
$slider-thumb-radial-opacity: $black-divider-opacity !default;
$slider-thumb-size:           ($material-icon-size / 2) !default;
$slider-track-bg:             rgba(0, 0, 0, 0.26) !default;
$slider-track-bg-active:      rgba(0, 0, 0, 0.38) !default;
$slider-track-height:         0.125rem !default;

// Snackbar
// Based on https://material.io/guidelines/components/snackbars-toasts.html#snackbars-toasts-specs

$snackbar-bg:                     #323232 !default;
$snackbar-border-radius:          $border-radius !default;
$snackbar-breakpoint:             sm !default;
$snackbar-btn-color:              theme-color(secondary) !default;
$snackbar-btn-color-hover:        theme-color-light(secondary) !default;
$snackbar-color:                  $white-primary !default;
$snackbar-elevation-shadow:       map-get($elevation-shadows, 6) !default;
$snackbar-font-size:              0.875rem !default;
$snackbar-height-multi:           5rem !default;
$snackbar-inner-spacer-x:         1.5rem !default;
$snackbar-inner-spacer-x-desktop: 3rem !default;
$snackbar-line-height:            $line-height-base !default;
$snackbar-margin-x:               $spacer-lg !default;
$snackbar-margin-y:               $spacer-lg !default;
$snackbar-max-width:              35.5rem !default;
$snackbar-min-width:              18rem !default;
$snackbar-padding-x:              1.5rem !default;
$snackbar-padding-y:              0.875rem !default;
$snackbar-padding-y-multi:        1.25rem !default;

// Stepper
// Based on https://material.google.com/components/steppers.html#steppers-specs

$stepper-bg:                $body-bg !default;
$stepper-border-color:      $material-color-grey-400 !default;
$stepper-border-width:      $border-width !default;
$stepper-icon-bg:           $black-hint !default;
$stepper-icon-bg-active:    theme-color(primary) !default;
$stepper-icon-color:        $white-primary !default;
$stepper-icon-font-size:    0.75rem !default;
$stepper-icon-font-weight:  $font-weight-regular !default;
$stepper-icon-height:       $material-icon-size !default;
$stepper-inner-spacer:      0.5rem !default;
$stepper-padding-x:         1.5rem !default;
$stepper-padding-y:         1.5rem !default;
$stepper-text-color:        $black-hint !default;
$stepper-text-color-active: $black-primary !default;
$stepper-text-font-size:    0.875rem !default;
$stepper-text-font-weight:  $font-weight-regular !default;

// Tab (Bootstrap tab)
// Based on https://material.google.com/components/tabs.html#tabs-specs

$nav-tab-bg-hover:         $black-divider !default;
$nav-tab-border-color:     $border-color !default;
$nav-tab-border-width:     $border-width !default;
$nav-tab-color:            $black-primary !default;
$nav-tab-color-active:     theme-color(secondary) !default;
$nav-tab-color-disabled:   $black-hint !default;
$nav-tab-font-size:        0.875rem !default;
$nav-tab-font-weight:      $font-weight-medium !default;
$nav-tab-height:           3rem !default;
$nav-tab-indicator-bg:     theme-color(secondary) !default;
$nav-tab-indicator-height: 0.125rem !default;
$nav-tab-line-height:      1 !default;
$nav-tab-link-opacity:     0.7 !default;
$nav-tab-link-padding-x:   0.75rem !default;
$nav-tab-link-padding-y:   (($nav-tab-height - $nav-tab-font-size * $nav-tab-line-height) / 2) !default;

// Text field (Bootstrap form control)
// Based on https://material.io/guidelines/components/text-fields.html#text-fields-states

$textfield-border-color:       rgba(0, 0, 0, 0.32) !default;
$textfield-border-color-focus: theme-color(secondary) !default;
$textfield-border-color-hover: rgba(0, 0, 0, 0.87) !default;
$textfield-border-width:       $border-width !default;
$textfield-border-width-focus: ($textfield-border-width * 2) !default;
$textfield-border-width-hover: ($textfield-border-width * 2) !default;
$textfield-color:              $black-primary !default;
$textfield-color-disabled:     $black-hint !default;
$textfield-hint-color:         $black-secondary !default;
$textfield-margin-x:           $spacer !default;
$textfield-plaintext-color:    $body-color !default;

$textfield-font-size:          .8rem !default;
$textfield-font-size-lg:       2.125rem !default;
$textfield-font-size-sm:       0.8125rem !default;
$textfield-height:             2.25rem !default;
$textfield-height-lg:          3.75rem !default;
$textfield-height-sm:          2rem !default;
$textfield-line-height:        1.5 !default;
$textfield-line-height-lg:     1.176471 !default;
$textfield-line-height-sm:     1.538462 !default;
$textfield-margin-y:           0.5rem !default;
$textfield-margin-y-lg:        0.75rem !default;
$textfield-margin-y-sm:        0.25rem !default;
$textfield-padding-x:          0 !default;
$textfield-padding-x-lg:       0 !default;
$textfield-padding-x-sm:       0 !default;
$textfield-padding-y:          .5rem !default;
$textfield-padding-y-lg:       (($textfield-height-lg - $textfield-font-size-lg * $textfield-line-height-lg) / 2) !default;
$textfield-padding-y-sm:       (($textfield-height-sm - $textfield-font-size-sm * $textfield-line-height-sm) / 2) !default;

$textfield-select-bg-size:     ($material-icon-size / $textfield-font-size * 1em) !default;

// Text field: box
// Based on https://material.io/guidelines/components/text-fields.html#text-fields-text-field-boxes

$textfield-box-bg:             rgba(0, 0, 0, 0.06) !default;
$textfield-box-border-radius:  0 !default;

$textfield-box-height:         3.5rem !default;
$textfield-box-height-lg:      4.25rem !default;
$textfield-box-height-sm:      2.75rem !default;
$textfield-box-label-spacer-y: 0.5rem !default;
$textfield-box-padding-x:      0rem !default;
$textfield-box-padding-x-lg:   1rem !default;
$textfield-box-padding-x-sm:   0.75rem !default;
$textfield-box-padding-y:      (($textfield-box-height - $textfield-font-size * $textfield-line-height) / 2) !default;
$textfield-box-padding-y-lg:   (($textfield-box-height-lg - $textfield-font-size-lg * $textfield-line-height-lg) / 2) !default;
$textfield-box-padding-y-sm:   (($textfield-box-height-sm - $textfield-font-size-sm * $textfield-line-height-sm) / 2) !default;

// Text field: floating label
// Based on https://material.google.com/components/text-fields.html#text-fields-labels

$floating-label-color:           $textfield-hint-color !default;
$floating-label-color-focus:     theme-color(secondary) !default;
$floating-label-font-size:       0.75rem !default;
$floating-label-font-size-lg:    0.875rem !default;
$floating-label-font-size-sm:    0.625rem !default;

// Toolbar (Bootstrap navbar)
// Based on https://material.google.com/components/toolbars.html#toolbars-usage

$toolbar-color:                 $white-primary !default;
$toolbar-color-lighter:         $white-hint !default;
$toolbar-element-bg:            $black-divider !default;
$toolbar-element-border-radius: $border-radius !default;
$toolbar-element-height:        2.25rem !default;
$toolbar-element-opacity:       0.7 !default;
$toolbar-elevation-shadow:      map-get($elevation-shadows, 4) !default;
$toolbar-height:                4.5rem !default;
$toolbar-inner-spacer-x:        1rem !default;
$toolbar-link-font-size:        1rem !default;
$toolbar-link-height:           $toolbar-element-height !default;
$toolbar-link-line-height:      1 !default;
$toolbar-link-padding-x:        1rem !default;
$toolbar-link-padding-y:        (($toolbar-link-height - $toolbar-link-font-size * $toolbar-link-line-height) / 2) !default;
$toolbar-padding-x:             1rem !default;
$toolbar-padding-y:             1rem !default;
$toolbar-toggler-icon:          'menu' !default;
$toolbar-waterfall-bg:          theme-color(primary) !default;

$toolbar-dark-color:            $white-primary !default;
$toolbar-dark-color-lighter:    $white-hint !default;
$toolbar-dark-element-bg:       $white-divider !default;

// Tooltip (Bootstrap tooltip)
// Based on https://material.google.com/components/tooltips.html#tooltips-tooltips-desktop

$tooltip-bg:                $material-color-grey-700 !default;
$tooltip-border-radius:     $border-radius !default;
$tooltip-breakpoint:        md !default;
$tooltip-color:             $white-primary !default;
$tooltip-font-size:         0.875rem !default;
$tooltip-font-size-desktop: 0.625rem !default;
$tooltip-height:            2rem !default;
$tooltip-height-desktop:    1.375rem !default;
$tooltip-line-height:       $line-height-base !default;
$tooltip-margin:            1.5rem !default;
$tooltip-margin-desktop:    0.875rem !default;
$tooltip-opacity:           0.9 !default;
$tooltip-padding-x:         1rem !default;
$tooltip-padding-x-desktop: 0.5rem !default;
$tooltip-padding-y:         (($tooltip-height - $tooltip-font-size * $tooltip-line-height) / 2) !default;
$tooltip-padding-y-desktop: (($tooltip-height-desktop - $tooltip-font-size-desktop * $tooltip-line-height) / 2) !default;
$tooltip-scale:             0.87 !default;
$tooltip-zindex:            map-get($elevations, 24) !default;
